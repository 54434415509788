<template>
    <div>
        <psi-page-title :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></psi-page-title>
        <!-- Content always starts with a v-card  -->
        <v-card class="mt-4">
            <template v-for="(color,i) in colors">
                <template v-for="(variant,j) in variants">
                    <v-toolbar
                        :color="`${color} ${variant}`"
                        :key="`${i}-${j}`"
                        class="mb-2"
                        :dark="variant.search('darken') != -1"
                    >
                        <v-toolbar-title class="text-h6 font-weight-regular">{{color}} - {{variant}}</v-toolbar-title>
                    </v-toolbar>
                </template>
            </template>
        </v-card>
    </div>
</template>
<script>
export default {
    name: "application-colors",
    data() {
        return {
            colors: [
                "primary",
                "secondary",
                "accent",
                "info",
                "success",
                "warning",
                "error",
                "alternate1",
                "alternate2",
                "alternate3",
                "alternate4",
                "alternate5",
            ],
            variants: [
                "base",
                "lighten-1",
                "lighten-2",
                "lighten-3",
                "lighten-4",
                "lighten-5",
                "darken-1",
                "darken-2",
                "darken-3",
                "darken-4",
                "darken-5",
            ],
            page: {
                title: "Application Colors",
                icon: "mdi-palette",
            },
            breadcrumbs: [
                {
                    text: "Dashboard",
                    disabled: false,
                    to: "#",
                },
                {
                    text: "Application Uploads",
                    disabled: true,
                },
            ],
        };
    },
    mounted() {},
};
</script>

<style scoped>
</style>